@import "variables";

html {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

body {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  background-color: $background-color;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield;
}