/*main elements*/
$primary-color: #F44336;
$primary-light-color: #F77B72;
$primary-dark-color: #F13429;

/*secondary elements*/
$secondary-color: #333333;
$secondary-light-color: #707070;
$secondary-dark-color: #272727;

/*basic background*/
$background-color: #2D2D2D;
/*cards and like this*/
$surface-color: #2D2D2D;

$error-color: #B00020;

:export {
    primaryColor: $primary-color;
    primaryLightColor: $primary-light-color;
    primaryDarkColor: $primary-dark-color;
    secondaryColor: $secondary-color;
    secondaryLightColor: $secondary-light-color;
    secondaryDarkColor: $secondary-dark-color;
    backgroundColor: $background-color;
    surfaceColor: $surface-color;
    errorColor: $error-color;
}